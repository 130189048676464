import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './reducers';
import { CHECKOUT_STATUS } from 'constants';

// Initial states
const initialState = {
  productsList: {
    products: [],
    isLoading: true,
    error: null,
  },
  checkout: {
    status: CHECKOUT_STATUS.INITIAL,
    customer: {},
    payment: {},
    products: [],
    totalCost: 0,
    scoutCode: '',
    scoutName: '',
    sale: {},
    isLoading: true,
    error: null,
  },
};

// Creating store
let store;
const isServer = typeof window === 'undefined';
if (isServer) {
  store = configureStore({
    reducer: { rootReducer },
    preloadedState: initialState,
  });
} else {
  const expireReducer = require('redux-persist-expire');

  const persistedReducers = persistReducer(
    {
      key: 'root',
      version: 1,
      storage,
      whitelist: ['productsList', 'checkout'],
      transforms: [
        expireReducer('productsList', {
          persistedAtKey: '__persisted_at',
          expireSeconds: 60 * 60 * 1, // 1 hour
          expiredState: initialState.productsList,
          autoExpire: true,
        }),
        expireReducer('checkout', {
          persistedAtKey: '__persisted_at',
          expireSeconds: 60 * 30, // 30 minutes
          expiredState: initialState.checkout,
          autoExpire: true,
        }),
      ],
    },
    rootReducer
  );

  store = configureStore({
    reducer: persistedReducers,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
  });
}

export default store;
