import PropTypes from 'prop-types';

const Button = (props) => {
  const {
    color = 'primary',
    size = 'md',
    icon,
    iconSize = 'md',
    iconPosition = 'left',
    rounded = 'md',
    displayType,
    styles,
    children,
    ...rest
  } = props;

  const setColor = () => {
    switch (color) {
      case 'primary':
        return `
          text-white bg-red-500 border-2 border-red-500
          hover:bg-white hover:text-blue-500  hover:border-blue-500
          active:bg-red-500 active:text-white active:ring-4 active:border-white active:ring-red-500
        `;
      case 'secondary':
        return `
        text-blue-500 bg-gray-100 border-2 border-gray-100
        hover:bg-gray-200 hover:border-gray-200
        active:bg-gray-100 active:ring-4 active:border-white active:ring-gray-100
      `;
      case 'success':
        return 'bg-success-500 hover:bg-green-700 text-white';
      case 'error':
        return 'bg-error-500 text-white hover:bg-red-700';
      case 'warning':
        return 'bg-warning-500 hover:bg-warning-600 text-white';
      case 'info':
        return 'bg-blue-400 hover:bg-blue-800 text-white';
      case 'disabled':
        return 'bg-gray-100 text-gray-300 cursor-not-allowed';
      case 'link':
        return 'bg-transparent hover:bg-blue-500 text-blue-500 hover:text-white';
      default:
        return 'bg-blue-500 hover:bg-blue-800 text-white';
    }
  };

  const setSize = () => {
    switch (size) {
      case 'sm':
        return 'text-sm py-1 px-2';
      case 'md':
        return 'text-base py-1.5 px-4';
      case 'lg':
        return 'text-lg py-2 px-8 ';
      case 'xl':
        return 'text-xl py-2.5 px-10';
      default:
        return 'text-base py-2 px-4';
    }
  };

  const setRounded = () => {
    switch (rounded) {
      case 'sm':
        return 'rounded';
      case 'md':
        return 'rounded-md';
      case 'lg':
        return 'rounded-lg';
      default:
        return '';
    }
  };

  const setDisplayType = () => {
    switch (displayType) {
      case 'block w-full':
        return 'block';
      case 'inline':
        return 'inline-block';
      case 'flex w-full':
        return 'flex';
      case 'inline-flex':
        return 'inline-flex';
      default:
        return 'inline-block w-full';
    }
  };

  const setIconSize = () => {
    switch (iconSize) {
      case 'sm':
        return 'w-4 h-4';
      case 'md':
        return 'w-6 h-6';
      case 'lg':
        return 'w-8 h-8';
      default:
        return 'w-6 h-6';
    }
  };

  return (
    <button
      className={`
         font-medium select-none flex items-center justify-center transition-all duration-100 ease-in-out
        ${setDisplayType(displayType)}
        ${setRounded(rounded)}
        ${setColor(color)}
        ${setSize(size)}
        ${styles}
      `}
      {...rest}>
      {icon && iconPosition === 'left' && <i className={`${setIconSize(iconSize)} mr-1`}>{icon}</i>}
      {children}
      {icon && iconPosition === 'right' && (
        <i className={`${setIconSize(iconSize)} ml-1`}>{icon}</i>
      )}
    </button>
  );
};

Button.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
  icon: PropTypes.string,
  iconSize: PropTypes.string,
  iconPosition: PropTypes.string,
  rounded: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  displayType: PropTypes.string,
  styles: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Button;
