import { GET_PRODUCTS, PRODUCTS_ERROR } from '../types';

const initialState = {
  products: [],
  isLoading: true,
  error: null,
};

const productReducers = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCTS:
      return {
        ...state,
        products: action.payload,
        isLoading: false,
      };
    case PRODUCTS_ERROR:
      return {
        error: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default productReducers;
