import { Helmet as Head } from 'react-helmet';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import TextField from '../components/UI/form/TextField';
import Button from '../components/UI/Button';
import { useEffect, useState } from 'react';
import { getGroups, registerScout } from 'utils/api/users';
import SelectField from '../components/UI/form/SelectField';
import { toast } from 'react-toastify';

const Logos = () => (
  <div className="flex justify-center mt-4 space-x-6">
    <img src="/images/logo1.png" alt="Boy Scouts of America" />
    <img src="/images/logo2.png" alt="Colby ridge dashboard" />
  </div>
);

const SignupView = () => {
  const [groups, setGroups] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    if (groups.length < 1 && !isLoading) {
      setLoading(true);
      getGroups().then((result) => {
        let _groups = [{ id: 0, name: '-- Select Your Unit --' }];
        result.filter((a) => a.id > 2).forEach((a) => _groups.push(a));
        console.log('getGroups', _groups);
        setGroups(_groups);
      });
    }
  }, [groups]);

  const handleOnSubmit = async (values) => {
    registerScout(values).then((result) => {
      if (!result.success) {
        return toast.error(result.error || result.user);
      }
      setSuccess(result.success);
    });
  };

  return (
    <div style={{ position: 'absolute', top: 0, height: '100%', width: '100%' }}>
      <Head>
        <title>Scout registration - ColbyRidge Dashboard</title>
        <meta name="description" content={'Scouts can signup'} />
      </Head>
      <div className="grid w-full min-h-screen px-6 pb-5 bg-blue-500">
        <div className="mb-8">
          <Logos />
        </div>
        <div className="flex flex-col mx-auto text-center item-center">
          <h2 className="flex flex-col text-3xl font-extrabold text-white sm:text-4xl">
            <span>Scout Registration</span>
          </h2>
          <section
            style={success !== true ? { marginTop: 20 } : { display: 'none' }}
            className="w-full col-span-12 px-6 pt-6 pb-8 mx-auto bg-white text-left rounded sm:col-span-5">
            <Formik
              initialValues={{
                screenname: '',
                groupId: '',
                role: 'scout',
                name: '',
                email: '',
              }}
              validationSchema={Yup.object({
                name: Yup.string().required('Name is required'),
                screenname: Yup.string().required('Username is required'),
                email: Yup.string().email('Invalid email address').required('Email is required'),
                groupId: Yup.number().required('Unit is required'),
              })}>
              {({ errors, touched, values }) => (
                <Form className="flex flex-col space-y-4">
                  <TextField
                    label="Your Name"
                    name="name"
                    type="text"
                    placeholder="First and Last Name"
                    error={errors.name}
                    haserror={errors.name && touched.name}
                    required
                  />
                  <TextField
                    label="The best email to contact you"
                    name="email"
                    type="email"
                    placeholder="email address"
                    error={errors.email}
                    haserror={errors.email && touched.email}
                    required
                  />
                  <TextField
                    label="User Name (How you log into the app)"
                    name="screenname"
                    type="text"
                    placeholder="User Name"
                    error={errors.screenname}
                    haserror={errors.screenname && touched.screenname}
                    required
                  />
                  <TextField
                    label="Password, you can change it later"
                    name="password"
                    type="text"
                    placeholder="******"
                    error={errors.password}
                    haserror={errors.password && touched.password}
                    required
                  />
                  <SelectField
                    label="Select Unit"
                    name="groupId"
                    options={groups?.map((group) => ({
                      value: group.id,
                      label: group.name,
                    }))}
                    error={errors.groupId}
                    haserror={errors.groupId && touched.groupId}
                    required
                  />
                  <Button
                    color={
                      Object.keys(errors).length > 0 || Object.keys(touched).length === 0
                        ? 'disabled'
                        : 'primary'
                    }
                    type="button"
                    size="xl"
                    onClick={() => handleOnSubmit(values)}
                    disabled={Object.keys(errors).length > 0 || Object.keys(touched).length === 0}>
                    Create Your Account
                  </Button>
                </Form>
              )}
            </Formik>
          </section>
          <div className="w-full mx-auto">
            <p className="mt-8 text-xl font-medium leading-6 text-blue-100">
              Already have an account? Download our mobile app
            </p>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <a
                href="https://apps.apple.com/us/app/colby-ridge-fundraising/id1553796074"
                title="Download the app">
                <img
                  style={{ height: 100 }}
                  src={'/images/google-play-badge.png'}
                  alt="Download on the Apple AppStore"
                />
              </a>
              <a
                href="https://apps.apple.com/us/app/colby-ridge-fundraising/id1553796074"
                title="Download the app">
                <img
                  style={{ height: 70, marginTop: 15 }}
                  src={'/images/Download_on_the_App_Store.svg'}
                  alt="Download on the Apple AppStore"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupView;
