import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';

import { clearCheckout } from 'store/actions/checkoutAction';
import { CHECKOUT_STATUS } from 'constants';
import routes from 'config/routes';
import ScrollToTop from 'hooks/useScrollToTop';

import Header from 'components/partials/Header';
import Footer from 'components/partials/Footer';

import 'react-toastify/dist/ReactToastify.css';

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { status } = useSelector((state) => state.checkout);

  useEffect(() => {
    if (
      (status === CHECKOUT_STATUS.COMPLETED || status === CHECKOUT_STATUS.INITIAL) &&
      location.pathname !== '/order-completed'
    ) {
      dispatch(clearCheckout());
    }
  }, [location.pathname]);

  return (
    <>
      <ScrollToTop />
      <Header />
      <main className="min-h-screen">
        <Routes>
          {routes.map(({ path, Component }) => (
            <Route key={path} path={path} element={<Component />} />
          ))}
        </Routes>
      </main>
      <Footer />
      <ToastContainer
        role="alert"
        limit={10}
        autoClose={1800}
        position={toast.POSITION.BOTTOM_RIGHT}
        hideProgressBar
        closeOnClick
        pauseOnHover
      />
    </>
  );
}

export default App;
