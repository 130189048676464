import SignupView from 'views/SignupView';
import PrivacyPolicy from 'views/PrivacyPolicy';
import EndOfSaleMessage from 'views/EndOfSale';
// import HomeView from 'views/HomeView';
// import ShoppingCartView from 'views/ShoppingCartView';
// import CheckoutView from 'views/CheckoutView';
// import OrderCompletedView from 'views/OrderCompletedView';
// import RedirectView from 'views/RedirectView';

/**
 * Removed Online Sale
 */
// const routes = [
//   { path: '/', name: 'Online sale', Component: HomeView },
//   { path: '/cart', name: 'Shopping cart', Component: ShoppingCartView },
//   { path: '/checkout', name: 'Checkout', Component: CheckoutView },
//   { path: '/order-completed', name: 'Order completed', Component: OrderCompletedView },
//   { path: '/s/:id', name: 'Order completed', Component: RedirectView },
//   { path: '/signup', name: 'Scout Signup', Component: SignupView },
//   { path: '/register/scout', name: 'Scout Signup', Component: SignupView },
//   { path: '/privacy', name: 'Scout Signup', Component: PrivacyPolicy },
// ];
const routes = [
  { path: '/', name: 'Online sale', Component: EndOfSaleMessage },
  { path: '/cart', name: 'Shopping cart', Component: EndOfSaleMessage },
  { path: '/checkout', name: 'Checkout', Component: EndOfSaleMessage },
  { path: '/order-completed', name: 'Order completed', Component: EndOfSaleMessage },
  { path: '/s/:id', name: 'Order completed', Component: EndOfSaleMessage },
  { path: '/signup', name: 'Scout Signup', Component: SignupView },
  { path: '/register/scout', name: 'Scout Signup', Component: SignupView },
  { path: '/privacy', name: 'Privacy', Component: PrivacyPolicy },
];

export default routes;
