import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';

const Icon = (props) => {
  const setSize = (size) => {
    switch (size) {
      case 'xs':
        return 'w-4'; // 16px
      case 'sm':
        return 'w-6'; // 24px
      case 'md':
        return 'w-8'; // 32px
      case 'lg':
        return 'w-10'; // 40px
      case 'xl':
        return 'w-12'; // 48px
      case '2xl':
        return 'w-16'; // 64px
      default:
        return 'w-8';
    }
  };

  const setColor = (color) => {
    switch (color) {
      case 'primary':
        return 'text-blue-500';
      case 'secondary':
        return 'text-red-500';
      case 'success':
        return 'text-success-500';
      case 'danger':
        return 'text-error-500';
      case 'warning':
        return 'text-warning-500';
      case 'info':
        return 'text-blue-400';
      case 'light':
        return 'text-gray-100';
      case 'dark':
        return 'text-gray-900';
      default:
        return 'text-current';
    }
  };

  const setRotation = (rotation) => {
    switch (rotation) {
      case '90':
        return 'rotate-90';
      case '180':
        return 'rotate-180';
      case '270':
        return 'rotate-270';
      default:
        return '';
    }
  };

  return (
    <ReactSVG
      src={require('assets/img/icons/' + props.name + '.svg')}
      className={`${setColor(props.color)} ${setSize(props.size)} ${setRotation(props.rotate)} ${
        props.styles
      }`}
      wrapper="div"
      {...props}
    />
  );
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.string,
  color: PropTypes.string,
  styles: PropTypes.string,
  rotate: PropTypes.string,
};
export default Icon;
