import PropTypes from 'prop-types';
import { useField, Field } from 'formik';

const RequiredSign = () => {
  return <span className="text-base text-red-500">*</span>;
};

const SelectField = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const { id, name, required, fullwidth, error } = props;

  const isValid = error && error.length > 0;

  return (
    <div
      className={`flex flex-col justify-center col-span-12 ${
        !fullwidth ? 'sm:col-span-6' : 'sm:col-span-12'
      }`}>
      <label htmlFor={id || name} className="mb-1 text-sm font-normal text-gray-400 font-roboto">
        {label} {required && <RequiredSign />}
      </label>
      <Field
        as="select"
        {...field}
        {...props}
        className={`px-3 h-[42px] text-base font-normal border rounded-md font-roboto ${
          isValid
            ? 'border-red-500 text-red-500 placeholder-red-500'
            : 'text-gray-500 border-gray-400'
        }`}>
        {props.options &&
          props.options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
      </Field>
      {meta.touched && meta.error ? (
        <div className="mt-1 text-sm text-red-500">{meta.error}</div>
      ) : null}
    </div>
  );
};

SelectField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.string,
  fullwidth: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
};

export default SelectField;
