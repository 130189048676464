import { useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useClickAway } from 'react-use';
import Button from 'components/UI/Button';
import { useCart } from 'context/useCart';

import CartBucketItems from './CartBucketItems';
import Icon from 'components/UI/Icon';

const CartBucket = ({ children }) => {
  const navigate = useNavigate();

  const { items, clearCart, toggleCart, isCartOpen } = useCart();

  useEffect(() => {
    if (isCartOpen) {
      document.body.style.overflow = 'hidden';
      document.body.style.borderRight = '15px solid #ddd';
    }
    return () => {
      document.body.style.overflow = 'unset';
      document.body.style.borderRight = '0px';
    };
  }, [isCartOpen]);

  const buyMoreHandler = () => {
    toggleCart();
    navigate('/');
  };

  const Bucket = () => {
    const ref = useRef(null);

    useClickAway(ref, () => {
      toggleCart();
    });

    const viewCartHandler = () => {
      toggleCart();
      navigate('/cart');
    };

    const clearCartHandler = () => {
      clearCart();
    };

    const TotalCost = items.reduce((acc, item) => {
      return acc + item.price * item.quantity;
    }, 0);

    const EmptyState = () => (
      <section className="col-span-12 overflow-hidden lg:col-span-8 lg:mb-0 min-h-[50vh]">
        <div className="flex flex-col items-center justify-center mx-auto px-9 w-96 h-72">
          <div className="bg-blue-100 rounded-full p-1 h-[4rem] w-[4rem] flex items-center justify-center mb-[1.375rem]">
            <Icon name="shopping-cart" size="lg" color="primary" />
          </div>
          <h2 className="mb-[1.375rem] text-[1.375rem] font-bold text-blue-600">
            Time to add popcorn!
          </h2>
          <Button size="lg" color="primary" rounded="sm" onClick={() => buyMoreHandler()}>
            <span className="text-base font-medium">View all popcorn</span>
          </Button>
        </div>
      </section>
    );

    return (
      <div
        ref={ref}
        className="fixed top-0 right-0 z-10 flex flex-col h-screen text-left text-gray-800 bg-white border border-gray-100 rounded w-[30rem]">
        <>
          <section className="flex items-center justify-between w-full pt-12 pb-4 px-9">
            <h1 className="text-[1.75rem] font-semibold text-blue-500 font-slab">
              {items.length === 0 ? 'Your cart' : 'Popcorn added to cart'}
            </h1>
            <button type="button" className="w-8 cursor-pointer">
              <Icon
                name="x-icon"
                size="sm"
                color="primary"
                wrapper="span"
                onClick={() => toggleCart()}
              />
            </button>
          </section>
          {items.length > 0 ? (
            <div className="flex flex-col justify-between h-full pb-5 en">
              <section className="flex flex-col">
                <CartBucketItems items={items} />
              </section>
              <section className="flex flex-col items-end justify-end pr-9">
                <div className="flex items-end justify-end mt-2">
                  <div className="flex w-[298px] items-center justify-between border-t border-gray-200 py-4">
                    <span className="text-lg font-light text-gray-500 font-encode">Total:</span>
                    <h2 className="text-[2rem] font-bold text-blue-500">${TotalCost.toFixed(2)}</h2>
                  </div>
                </div>
                <div className="flex flex-col items-center  w-[298px] space-y-2.5 font-roboto">
                  <Button color="primary" size="lg" rounded="sm" onClick={() => buyMoreHandler()}>
                    Buy more
                  </Button>
                  <Button
                    color="secondary"
                    size="lg"
                    rounded="sm"
                    onClick={() => viewCartHandler()}>
                    View shopping cart
                  </Button>
                  <Button
                    color="secondary"
                    size="lg"
                    rounded="sm"
                    onClick={() => clearCartHandler()}>
                    Clear cart
                  </Button>
                </div>
              </section>
            </div>
          ) : (
            <EmptyState />
          )}
        </>
      </div>
    );
  };

  return (
    <>
      <span onClick={() => toggleCart()} disabled={isCartOpen}>
        {children}
      </span>
      {isCartOpen && <Bucket />}
    </>
  );
};

CartBucket.propTypes = {
  children: PropTypes.node.isRequired,
  items: PropTypes.arrayOf(PropTypes.object),
};

export default CartBucket;
