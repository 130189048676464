import { NavLink, useLocation } from 'react-router-dom';
import Icon from 'components/UI/Icon';

import CartBucket from 'components/CartBucket';
import Button from 'components/UI/Button';
import { useCart } from 'context/useCart';
import { getScoutName } from 'utils/api/users';
import { useState } from 'react';

export default function Header() {
  const location = useLocation();
  const { totalItems } = useCart();
  const [scoutName] = useState(getScoutName());

  console.log('Header::', scoutName, getScoutName());

  const scoutText =
    scoutName || getScoutName() ? (
      <p>Thank you for supporting {getScoutName()} and the BoyScouts of America</p>
    ) : null;
  return (
    <header className="flex flex-wrap flex-1 mb-16">
      <div className="h-[141px] flex items-center justify-center w-full px-4 mx-auto bg-blue-500 lg:px-8">
        <div className="relative grid w-full max-w-screen-xl grid-cols-12">
          <div className="absolute inset-y-0 right-0 flex items-center lg:hidden">
            {/* Mobile menu button*/}
            <NavLink to={'/cart'} className="flex items-center">
              <Button
                color="secondary"
                aria-label="Shopping Car Button"
                size="sm"
                rounded="md"
                styles="flex space-x-2 h-10 min-w-10 rounded-full">
                <Icon name="shopping-cart" size="xs" aria-hidden="true" />
                {totalItems > 0 && location.pathname !== '/order-completed' && (
                  <div className="flex items-center justify-center w-5 h-5 p-1 text-xs font-bold text-white bg-red-600 rounded-lg -right-2 -top-2">
                    {totalItems}
                  </div>
                )}
              </Button>
            </NavLink>
          </div>

          <div className="flex items-center justify-start flex-1 col-span-12 space-x-6 w-12/12 sm:col-span-6 xl:col-span-6 lg:space-x-12 sm:items-stretch">
            <NavLink
              to="/"
              className="flex items-center flex-shrink-0 space-x-2.5 text-white justify-center">
              <img
                alt="colby ridge logo"
                src="/images/logo1.png"
                className="object-contain w-20 h-20 lg:w-24 md:h-24"
              />
              <img
                alt="colby ridge logo"
                src="/images/logo2.png"
                className="object-contain w-20 h-20 lg:w-24 md:h-24"
              />
              {scoutText}
            </NavLink>
          </div>

          <div className="flex items-center justify-end w-full col-span-12 sm:col-span-6 xl:col-span-6">
            <div className="items-center justify-end hidden space-x-6 lg:flex">
              <CartBucket>
                <Button color="secondary" size="md" rounded="sm" styles="space-x-2 relative">
                  <Icon name="shopping-cart" size="xs" aria-hidden="true" />
                  <span className="font-roboto">Shopping Cart</span>
                  {totalItems > 0 && location.pathname !== '/order-completed' && (
                    <div className="flex items-center justify-center w-5 h-5 p-1 text-xs font-bold text-white bg-red-600 rounded-lg -right-2 -top-2">
                      {totalItems}
                    </div>
                  )}
                </Button>
              </CartBucket>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
