import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { CartProvider } from 'context/useCart';
import store from 'store/store';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Loader from 'components/UI/Loader';
import App from 'App';

const TheContainer = document.getElementById('root');
const TheRoot = ReactDOMClient.createRoot(TheContainer);

let persistor = persistStore(store);

TheRoot.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={<Loader />} persistor={persistor}>
        <CartProvider>
          <Router>
            <App />
          </Router>
        </CartProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
