import axios from 'axios';

const domain = window.location.host;
console.log('BaseUrl:domain', domain);

const domains = {
  'localhost:3000': process.env.REACT_APP_API_URL || 'http://localhost:3000/',
  'localhost:3001': process.env.REACT_APP_API_URL || 'http://localhost:3000/',
  'www.cornhuskerpopcorn-colbyridge.com': 'https://server.cornhuskerpopcorn-colbyridge.com/',
  'cornhuskerpopcorn-colbyridge.com': 'https://server.cornhuskerpopcorn-colbyridge.com/',
  'dev.cornhuskerpopcorn-colbyridge.com': 'https://api-dev.cornhuskerpopcorn-colbyridge.com/',
};

console.log('BaseUrl:domain', domain, domains[domain]);

const _URL = domains[domain] ? domains[domain] : 'https://server.cornhuskerpopcorn-colbyridge.com/';

const axiosClient = axios.create({
  baseURL: _URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  timeout: 10000,
  withCredentials: false,
});

export const BaseUrl = _URL;

export const normalizeURL = (start, end) => {
  return `${start}/${end}`.replace(/\/{2,}/g, '/');
};

export default class BaseService {
  apiResource = '';

  normalize(start, end) {
    return `${start}/${end}`.replace(/\/{2,}/g, '/');
  }

  get(url, config) {
    console.log('get', _URL);
    return axiosClient.get(this.normalize(this.apiResource, url), config ?? {});
  }

  post(url, data) {
    return axiosClient.post(this.normalize(this.apiResource, url), data);
  }

  put(url, data) {
    return axiosClient.put(this.normalize(this.apiResource, url), data);
  }

  patch(url, data) {
    return axiosClient.patch(this.normalize(this.apiResource, url), data);
  }

  delete(url) {
    return axiosClient.delete(this.normalize(this.apiResource, url));
  }
}
