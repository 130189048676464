/* eslint-disable no-prototype-builtins */
import { useContext, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import {
  initialState,
  cartReducer,
  createCartIdentifier,
  CartContext,
} from './reducers/cartReducers';
import useLocalStorage from 'hooks/useLocalStorage';

/**
 * @title useCart hook
 * @description A hook for managing the cart
 * @param {Object} props The props to pass to the hook
 * @returns {Object} The cart state and methods
 * @example
 * import { useCart } from 'context/useCart';
 * const { state, addItemToCart, updateCartItemQuantity, removeCartItem, clearCart, getItemFromCart, inCartItem, clearCartMetadata } = useCart();
 */
export const useCart = () => {
  const context = useContext(CartContext);

  if (!context) throw new Error('Expected to be wrapped in a CartProvider');

  return context;
};

/**
 * @title Cart Provider
 * @description A provider for managing the cart
 * @param {Object} props The props to pass to the provider
 * @returns {Object} The cart state and methods
 * @example
 * import { CartProvider } from 'context/useCart';
 */
export const CartProvider = ({
  children,
  id: cartId,
  defaultItems = [],
  onSetItems,
  onItemAdd,
  onItemUpdate,
  onItemRemove,
  storage = useLocalStorage,
  metadata,
}) => {
  const id = cartId ? cartId : createCartIdentifier();

  const [savedCart, saveCart] = storage(
    cartId ? `colby-ridge-cart-${id}` : `colby-ridge-cart`,
    JSON.stringify({
      id,
      ...initialState,
      items: defaultItems,
      metadata,
    })
  );

  const [state, dispatch] = useReducer(cartReducer, JSON.parse(savedCart));
  useEffect(() => {
    saveCart(JSON.stringify(state));
  }, [state, saveCart]);

  /**
   * @title Set Items
   * @description Sets the items in the cart
   * @param {Array} items The items to set
   * @returns {void}
   * @example
   * import { addItemsToCart } from 'context/useCart';
   */
  const addItemsToCart = (items) => {
    dispatch({
      type: 'SET_ITEMS',
      payload: items.map((item) => ({
        ...item,
        quantity: item.quantity || 1,
      })),
    });

    onSetItems && onSetItems(items);
  };

  /**
   * @title Add Item
   * @description Adds an item to the cart
   * @param {Object} item The item to add
   * @returns {void}
   * @example
   * import { addItemToCart } from 'context/useCart';
   * addItemToCart(item);
   */
  const addItemToCart = (item, quantity = 1) => {
    if (!item.id) throw new Error('You must provide an `id` for items');
    if (quantity <= 0) return;

    const currentItem = state.items.find((i) => i.id === item.id);

    if (!currentItem && !item.hasOwnProperty('price'))
      throw new Error('You must pass a `price` for new items');

    if (!currentItem) {
      const payload = { ...item, quantity };

      dispatch({ type: 'ADD_ITEM', payload });

      onItemAdd && onItemAdd(payload);

      return;
    }

    const payload = { ...item, quantity: currentItem.quantity + quantity };

    dispatch({
      type: 'UPDATE_ITEM',
      id: item.id,
      payload,
    });

    onItemUpdate && onItemUpdate(payload);
    return;
  };

  /**
   * @title Update Item
   * @description Updates an item in the cart
   * @param {Object} item The item to update
   * @returns {void}
   * @example
   * import { updateCartItem } from 'context/useCart';
   * updateCartItem({ id: '123', quantity: 2 });
   */
  const updateCartItem = ({ id }, payload) => {
    if (!id || !payload) {
      return;
    }

    dispatch({ type: 'UPDATE_ITEM', id, payload });

    onItemUpdate && onItemUpdate(payload);
  };

  /**
   * @title Update item quantity
   * @description Updates the quantity of an item in the cart
   * @param {Object} item The item to update
   * @returns {void}
   * @example
   * import { updateCartItemQuantity } from 'context/useCart';
   * updateCartItemQuantity({ id: '123', quantity: 2 });
   */
  const updateCartItemQuantity = ({ id, name, quantity }) => {
    if (quantity <= 0) {
      onItemRemove && onItemRemove(id);

      dispatch({ type: 'REMOVE_ITEM', id });

      return toast.warn(`${name} removed`);
    }

    const currentItem = state.items.find((item) => item.id === id);

    if (!currentItem) throw new Error('No such item to update');

    const payload = { ...currentItem, quantity };

    dispatch({
      type: 'UPDATE_ITEM',
      id,
      payload,
    });

    onItemUpdate && onItemUpdate(payload);
  };

  /**
   * @title Remove Item
   * @description Removes an item from the cart
   * @param {Object} item The item to remove
   * @returns {void}
   * @example
   * import { removeItemFromCart } from 'context/useCart';
   * removeItemFromCart({ id: '123', name: 'Item' });
   */
  const removeCartItem = ({ id, name }) => {
    if (!id) return;

    dispatch({ type: 'REMOVE_ITEM', id });

    onItemRemove && onItemRemove(id);
    return toast.warn(`${name} removed`);
  };

  /**
   * @title Clear Cart
   * @description Clears the cart
   * @returns {void}
   * @example
   * import { clearCart } from 'context/useCart';
   * clearCart();
   */
  const clearCart = () => {
    dispatch({
      type: 'EMPTY_CART',
    });
  };

  /**
   * @title Get Item
   * @description Gets an item from the cart
   * @param {String} id The id of the item to get
   * @returns {Object} The item
   * @example
   * import { getCartItem } from 'context/useCart';
   * getCartItem('123');
   */
  const getItemFromCart = (id) => state.items.find((i) => i.id === id);

  /**
   * @title Check if Item Exists In Cart
   * @description Checks if an item exists in the cart
   * @param {String} id The id of the item to check
   * @returns {Boolean} Whether the item exists in the cart
   * @example
   * import { inCartItem } from 'context/useCart';
   * inCartItem('123');
   */
  const inCartItem = (id) => state.items.some((i) => i.id === id);

  /**
   * @title Clear cart metadata
   * @description Clears the cart metadata
   * @returns {void}
   * @example
   * import { clearCartMetadata } from 'context/useCart';
   * clearCartMetadata();
   */
  const clearCartMetadata = () => {
    dispatch({
      type: 'CLEAR_CART_META',
    });
  };

  /**
   * @title Set cart metadata
   * @description Sets the cart metadata
   * @param {Object} metadata The metadata to set
   * @returns {void}
   * @example
   * import { setCartMetadata } from 'context/useCart';
   */
  const setCartMetadata = (metadata) => {
    if (!metadata) return;

    dispatch({
      type: 'SET_CART_META',
      payload: metadata,
    });
  };

  /**
   * @title Update cart metadata
   * @description Updates the cart metadata
   * @param {Object} metadata The metadata to update
   * @returns {void}
   * @example
   * import { updateCartMetadata } from 'context/useCart';
   * updateCartMetadata(item);
   */
  const updateCartMetadata = (metadata) => {
    if (!metadata) return;

    dispatch({
      type: 'UPDATE_CART_META',
      payload: metadata,
    });
  };

  /**
   * @title Toggle basket
   * @description Toggles the basket
   * @returns {void}
   * @example
   * import { toggleCart } from 'context/useCart';
   * toggleCart();
   */
  const toggleCart = () => {
    dispatch({
      type: 'TOGGLE_CART',
    });
  };

  return (
    <CartContext.Provider
      value={{
        ...state,
        addItemToCart,
        addItemsToCart,
        clearCart,
        getItemFromCart,
        updateCartItem,
        removeCartItem,
        updateCartItemQuantity,
        inCartItem,
        setCartMetadata,
        updateCartMetadata,
        clearCartMetadata,
        toggleCart,
      }}>
      {children}
    </CartContext.Provider>
  );
};

CartProvider.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  defaultItems: PropTypes.arrayOf(PropTypes.object),
  onSetItems: PropTypes.func,
  onItemAdd: PropTypes.func,
  onItemUpdate: PropTypes.func,
  onItemRemove: PropTypes.func,
  storage: PropTypes.func,
  metadata: PropTypes.object,
};
