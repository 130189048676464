import { useSignOut, useAuthUser, useIsAuthenticated } from 'react-auth-kit';
import Service from 'api/Service';

class AuthService extends Service {
  apiResource = '/auth';

  /**
   * @title:  Get Profile
   * @description:  This function gets the profile
   * @returns {Object}
   * @example:
   * const profile = await AuthService.getProfile();
   */
  getProfile = () => {
    const isAuthenticated = useIsAuthenticated();

    if (isAuthenticated()) {
      const getUserData = useAuthUser();
      return getUserData();
    }
    return null;
  };

  /**
   * @title:  Gets Users List
   * @description:  This function gets the users list
   * @returns {Object}
   * @example:
   * const users = await AuthService.getUsers();
   */
  getUsers = async () => {
    try {
      const { data } = await this.get(``);
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * @title:  Sign In User
   * @description:  This function signs in the user
   * @param {Object} credentials
   * @returns {Object}
   * @example:
   * const user = await AuthService.signIn({
   *  email: '
   * password: '
   * });
   */
  signIn = async (credentials) => {
    try {
      const res = this.post('/login', credentials);
      return res;
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * @title:  Sign Out User
   * @description:  This function signs out the user
   * @returns {Object}
   * @example:
   * const user = await AuthService.signOut();
   */
  signOut = () => {
    const signOut = useSignOut();
    return signOut();
  };
}

export default new AuthService();
