import PropTypes from 'prop-types';

const CartBucketItems = ({ items }) => {
  return (
    <>
      <section className="flex justify-between w-full px-9">
        <ul className="overflow-x-hidden overflow-y-auto divide-y-2 divide-gray-100 max-h-[54vh]">
          {items.map((item, idx) => (
            <li
              key={idx}
              className="flex items-center justify-between w-full h-auto py-4 overflow-hidden">
              <div className="grid grid-cols-12 space-x-2">
                <div className="flex items-center justify-center w-24 h-24 col-span-3 overflow-hidden">
                  <img
                    className="object-contain object-center w-24 h-24"
                    src={item.image}
                    alt={item.name}
                  />
                </div>
                <section className="flex flex-col w-auto h-auto col-span-9">
                  <h1 className="flex items-center space-x-1.5 text-lg font-bold text-gray-800 tracking-wide">
                    <span>{item.name}</span>
                    <div className="flex items-center justify-center w-4 h-4 text-[9px] text-white bg-blue-500 rounded-sm leading-6">
                      {item.quantity}
                    </div>
                  </h1>
                  <p className="mb-1 text-sm font-normal text-gray-800 font-roboto">
                    {item.description}
                  </p>
                  <div className="block">
                    <h2 className="text-xl font-bold text-blue-600">
                      ${item.itemTotal.toFixed(2)}
                    </h2>
                  </div>
                </section>
              </div>
            </li>
          ))}
        </ul>
      </section>
    </>
  );
};

CartBucketItems.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
};
export default CartBucketItems;
