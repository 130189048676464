import { Helmet as Head } from 'react-helmet';

const Logos = () => (
  <div className="flex justify-center mt-4 space-x-6">
    <img src="/images/logo1.png" alt="Boy Scouts of America" />
    <img src="/images/logo2.png" alt="Colby ridge dashboard" />
  </div>
);

const EndOfSaleMessage = () => {
  return (
    <div style={{ position: 'absolute', top: 0, height: '100%', width: '100%' }}>
      <Head>
        <title>The sale has ended</title>
        <meta name="description" content={'Thank You For Your Support'} />
      </Head>
      <div className="grid w-full min-h-screen px-6 pb-5 bg-blue-500">
        <div className="mb-8">
          <Logos />
        </div>
        <div className="flex flex-col mx-auto text-center item-center">
          <h2 className="flex flex-col text-3xl font-extrabold text-white sm:text-4xl">
            <span>Thank You For Your Support</span>
          </h2>
        </div>
      </div>
    </div>
  );
};

export default EndOfSaleMessage;
