import Service from 'api/Service';

let SCOUT_NAME = false;
let SCOUT_CODE = false;

class UserService extends Service {
  apiResource = '/users';

  /**
   * @title:  Screenname Validator
   * @description:  This function validates the screenname
   * @param {String} scoutCode
   * @returns {Object}
   * @example:
   * const scoutCodeValidator = await UserService.scoutCodeValidator(scoutCode);
   */

  nap = (ms) => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(true), ms);
    });
  };
  registerScout = async (scout) => {
    await this.nap(100);
    try {
      scout.groupId = Number(scout.groupId);
      const { data } = await this.post(`/`, scout);
      console.log('scoutRegister', data);
      return data;
    } catch (error) {
      console.log('scoutCodeValidator::error', error);
    }
  };
  scoutCodeValidator = async (scoutCode) => {
    await this.nap(100);
    try {
      const { data } = await this.get(`/validate/${scoutCode}`);
      if (data.name) {
        await localStorage.setItem('scoutName', data.name);
        await localStorage.setItem('scoutCode', scoutCode);
        SCOUT_NAME = data.name;
        SCOUT_CODE = scoutCode;
      }
      return data;
    } catch (error) {
      console.log('scoutCodeValidator::error', error);
    }
  };
  getScoutName = () => {
    if (SCOUT_NAME) return SCOUT_NAME;
    SCOUT_NAME = localStorage.getItem('scoutName');
  };
  getScoutCode = () => {
    if (SCOUT_CODE) return SCOUT_CODE;
    SCOUT_CODE = localStorage.getItem('scoutCode');
    return SCOUT_CODE;
  };
}

export default new UserService();
