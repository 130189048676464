export const SET_CHECKOUT_TYPE = {
  CUSTOMER: 'customer',
  STATUS: 'status',
  PRODUCTS: 'products',
  TOTAL_COST: 'totalCost',
  SHIPPING_COST: 'shippingCost',
  SCOUT_CODE: 'scoutCode',
  SCOUT_NAME: 'scoutName',
  SALE: 'sale',
};

export const CHECKOUT_STATUS = {
  INITIAL: 'initial',
  PROCESSING: 'processing',
  COMPLETED: 'completed',
};
