import Service from 'api/Service';

class GroupService extends Service {
  apiResource = '/groups';

  /**
   * @title:  Screenname Validator
   * @description:  This function validates the screenname
   * @param {String} scoutCode
   * @returns {Object}
   * @example:
   * const scoutCodeValidator = await UserService.scoutCodeValidator(scoutCode);
   */

  nap = (ms) => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(true), ms);
    });
  };
  getGroups = async () => {
    await this.nap(100);
    try {
      const { data } = await this.get(`/`);
      data.sort((a, b) => {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        return nameA < nameB ? -1 : nameA < nameB ? 1 : 0;
      });
      return data;
    } catch (error) {
      console.log('scoutCodeValidator::error', error);
    }
  };
}

export default new GroupService();
