import { CHECKOUT_STATUS, SET_CHECKOUT_TYPE } from 'constants/index';
import { SET_CHECKOUT, SET_CHECKOUT_ERROR, CLEAR_CHECKOUT } from '../types';

const initialState = {
  status: CHECKOUT_STATUS.INITIAL,
  customer: {},
  totalCost: 0,
  scoutCode: '',
  products: [],
  loading: true,
  error: null,
  scoutName: '',
  sale: {},
};

const checkoutReducers = (state = initialState, action) => {
  let payload = null;
  console.log('checkoutReducers', action.type, action.payload);
  switch (action.type) {
    case SET_CHECKOUT:
      switch (action.payload.type) {
        case SET_CHECKOUT_TYPE.CUSTOMER:
          payload = {
            name: action.payload.name,
            street1: action.payload.street1,
            street2: action.payload.street2, // optional
            city: action.payload.city,
            state: action.payload.state,
            zip: action.payload.zip,
            email: action.payload.email,
            phone: action.payload.phone,
          };

          if (!payload.street2) {
            delete payload.street2;
          }

          return {
            ...state,
            customer: payload,
            loading: false,
          };

        case SET_CHECKOUT_TYPE.SALE:
          return {
            ...state,
            sale: action.payload.sale,
            loading: false,
          };

        case SET_CHECKOUT_TYPE.SCOUT_CODE:
          return {
            ...state,
            scoutCode: action.payload.scoutCode,
            loading: false,
          };

        case SET_CHECKOUT_TYPE.SCOUT_NAME:
          return {
            ...state,
            scoutName: action.payload.scoutName,
            loading: false,
          };
        case SET_CHECKOUT_TYPE.PRODUCTS:
          payload = () => {
            const products = [];
            action.payload.products.forEach((product) => {
              products.push({
                id: product.id,
                name: product.name,
                description: product.description,
                image: product.image,
                sku: product.sku,
                itemTotal: product.itemTotal,
                active: product.active,
                price: product.price,
                quantity: product.quantity,
              });
            });
            return products;
          };
          return {
            ...state,
            products: payload(),
            loading: false,
          };

        case SET_CHECKOUT_TYPE.STATUS:
          return {
            ...state,
            status: action.payload.status,
            loading: false,
          };

        case SET_CHECKOUT_TYPE.TOTAL_COST:
          return {
            ...state,
            totalCost: action.payload.totalCost,
            loading: false,
          };
      }
      return state;

    case SET_CHECKOUT_ERROR:
      return {
        error: action.payload,
        loading: false,
      };

    case CLEAR_CHECKOUT:
      return initialState;
    default:
      return state;
  }
};

export default checkoutReducers;
