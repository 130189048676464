import inflection from 'inflection';
import BaseService from './core/BaseService';

const getApiResourceName = Symbol('getApiResourceName');
const getPrimaryKey = Symbol('getPrimaryKey');

export default class Service extends BaseService {
  apiResource = this[getApiResourceName]();
  primaryKey = 'id';

  all() {
    return this.get('');
  }

  show(resource) {
    return this.get(`/${this[getPrimaryKey](resource)}`);
  }

  store(resource) {
    return super.post('', resource);
  }

  update(resource, ignorePrimaryKey = false) {
    const url = ignorePrimaryKey ? '/' : `/${this[getPrimaryKey](resource)}`;
    return super[ignorePrimaryKey ? 'post' : 'put'](url, resource);
  }

  delete(resource) {
    return super.delete(resource);
  }

  [getApiResourceName]() {
    return `/${inflection.pluralize(this.constructor.name.replace('Service', '').toLowerCase())}`;
  }

  [getPrimaryKey](resource) {
    if (typeof resource === 'object') {
      return resource[this.primaryKey];
    }
    return String(resource);
  }
}
