import { SET_CHECKOUT, SET_CHECKOUT_ERROR, CLEAR_CHECKOUT } from '../types';

export const setCheckout = (payload) => (dispatch) => {
  try {
    dispatch({
      payload,
      type: SET_CHECKOUT,
    });
  } catch (error) {
    dispatch({
      payload: error,
      type: SET_CHECKOUT_ERROR,
    });
  }
};
export const clearCheckout = () => (dispatch) => {
  dispatch({
    payload: null,
    type: CLEAR_CHECKOUT,
  });
};
