import React from 'react';
import PropTypes from 'prop-types';
import { Field, ErrorMessage } from 'formik';

const RequiredSign = () => {
  return <span className="text-base text-red-500">*</span>;
};
function TextInputField(props) {
  const { id, name, label, fullwidth, required, error, render, ...rest } = props;

  const isValid = error && error.length > 0;
  return (
    <div
      className={`flex flex-col justify-center col-span-12 ${
        fullwidth ? 'sm:col-span-12' : 'sm:col-span-6'
      }`}>
      <label htmlFor={id || name} className="mb-1 text-base font-normal text-gray-400 font-roboto">
        {label}
        {required && <RequiredSign />}
      </label>
      <Field
        id={id}
        name={name}
        className={`px-3 h-[42px] text-base font-normal border rounded-md font-roboto ${
          isValid
            ? 'border-red-500 text-red-500 placeholder-red-500'
            : 'text-gray-500 border-gray-400'
        }`}
        {...rest}>
        {render}
      </Field>
      <span className="mt-1 text-xs text-red-500">
        <ErrorMessage name={name} />
      </span>
    </div>
  );
}

TextInputField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.string,
  fullwidth: PropTypes.bool,
  render: PropTypes.func,
};
export default TextInputField;
