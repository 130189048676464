import PropTypes from 'prop-types';

const Loader = ({ text = 'Loading...' }) => {
  return (
    <div className="loading">
      <div className="loading-blocks">
        <div className="block" />
        <div className="block" />
        <div className="block" />
      </div>
      <div className="loading-blocks__text">{text}</div>
    </div>
  );
};

Loader.propTypes = {
  text: PropTypes.string,
};

export default Loader;
