import UserService from 'api/UserService';
import AuthService from 'api/AuthService';
import GroupsService from 'api/GroupsService';

/**
 * @title Get user info
 * @description Returns user info
 * @returns {object} - User info
 * @example
 * getProfile();
 */
export const getProfile = async () => {
  return await AuthService.getProfile();
};

/**
 * @title Get all users
 * @description Returns all users
 * @returns {object} - All users
 * @example
 * getUsers();
 */
export const getUsers = async () => {
  return await AuthService.getUsers();
};

/**
 * @title Sign in user
 * @description Signs in user
 * @param {object} data - User data
 * @returns {object} - User info
 * @example
 * signIn(data);
 */
export const signIn = async (data) => {
  return await AuthService.signIn(data);
};

/**
 * @title Sign Out user
 * @description Signs out user
 * @returns {object} - User info
 * @example
 * signOut();
 */
export const signOut = async () => {
  return await AuthService.signOut();
};

/**
 * @title Is user logged in
 * @description Returns true if user is logged in
 * @returns {boolean} - True if user is logged in
 * @example
 * isAuthenticated();
 */
export const isAuthenticated = async () => {
  return await AuthService.isAuthenticated();
};

/**
 * @title Screenname Validator
 * @description This function validates the screenname
 * @param {String} scoutCode
 * @returns {Object}
 * @example
 * scoutCodeValidator(scoutCode);
 */
export const scoutCodeValidator = async (scoutCode) => {
  return await UserService.scoutCodeValidator(scoutCode);
};
export const getScoutName = () => {
  return UserService.getScoutName();
};
export const getScoutCode = () => {
  return UserService.getScoutCode();
};
export const getGroups = () => {
  return GroupsService.getGroups();
};
export const registerScout = (scout) => {
  return UserService.registerScout(scout);
};
